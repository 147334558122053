import React from "react";
import { graphql } from "gatsby";
import Layout from "src/components/layout/layout";
import { TopProjectBanner } from "src/components/banner";
import { HelmetComponent } from "components/helmet";

const PostPage = ({
  data: {
    project: { slug, title, subTitle, folder, contents, next, prev, meta, date },
  },
}) => {
  return (
    <Layout key={folder}>
      <HelmetComponent {...meta} />
      <TopProjectBanner
        slug={slug}
        folder={folder}
        contents={contents}
        title={title}
        subTitle={subTitle}
        next={next}
        prev={prev}
        date={date}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    project(slug: { eq: $slug }) {
      slug
      subTitle
      title
      imageName
      folder
      contents
      next
      prev
      date
      meta {
        title
        description
        keyword
        image
      }
    }
  }
`;

export default PostPage;
